// create an EditPeople react component

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import { useNavigate } from "react-router";

export default function EditPeople() {
  const { id } = useParams();
  const [people, setPeople] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const docRef = doc(db, "people", id);

  const getPeople = async () => {
    const data = await getDoc(docRef);
    console.log(`GetData card From FireStore`);

    // check if data is empty
    if (data.empty) {
      console.log(`data is empty`);
      setLoading(false);
      return;
    }

    // log a field from the first document
    console.log(`data`, data.data());
    setPeople(data.data());

    console.log(`people`, people);
    console.log(`people.Name`, people.Name);

    // setLoading(false);
  };

  useEffect(() => {
    getPeople();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(`people.HomePAge`, people.HomePageUrl);

    try {
      const postDoc = doc(db, "people", id);
      await updateDoc(postDoc, {
        Name: people.Name,
        Email: people.Email,
        PicUrl: people.PicUrl,
        // add HomePageUrl if it does not have an  undefined value

        ...(people.HomePageUrl && { HomePageUrl: people.HomePageUrl }),
        ...(people.AboutDescription && {
          AboutDescription: people.AboutDescription,
        }),
        ...(people.Telephonenr && { Telephonenr: people.Telephonenr }),
      });

      navigate("/home");
    } catch (error) {
      console.log(`error`, error.message);
    }
  };

  return (
    <div className="AppContainer">
    <div>
      <br></br>
      <img src={people.PicUrl} alt="" height="200"></img>
      <br></br>
      <br></br>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicText">
          <Form.Control
            type="text"
            placeholder="Name"
            onChange={(e) => setPeople({ ...people, Name: e.target.value })}
            value={people.Name}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicText">
          <Form.Control
            type="text"
            placeholder="Email"
            onChange={(e) => setPeople({ ...people, Email: e.target.value })}
            value={people.Email}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicText">
          <Form.Control
            type="text"
            placeholder="PicUrl"
            onChange={(e) => setPeople({ ...people, PicUrl: e.target.value })}
            value={people.PicUrl}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="HomePageUrl">
          <Form.Control
            type="text"
            placeholder="HomePageUrl"
            onChange={(e) =>
              setPeople({ ...people, HomePageUrl: e.target.value })
            }
            value={people.HomePageUrl}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicText">
          <Form.Control
            type="text"
            placeholder="Telephone"
            onChange={(e) =>
              setPeople({ ...people, Telephonenr: e.target.value })
            }
            value={people.Telephonenr}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicText">
          <Form.Control
            as="textarea"
            placeholder="About"
            onChange={(e) =>
              setPeople({ ...people, AboutDescription: e.target.value })
            }
            value={people.AboutDescription}
            rows={4}
          />
        </Form.Group>

        <div className="d-grid gap-2">
          <Button variant="primary" type="submit">
            Update
          </Button>
        </div>
      </Form>
      <br>
      </br>
      <br>
      </br>
    </div>
    </div>
  );
}
