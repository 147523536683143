import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase-config";
import Table from "react-bootstrap/Table";

const Home = () => {
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();

  const [peopleList, setPeopleList] = useState([]);

  const PostPeopleColl = query(
    collection(db, "people"),
    where("User.userUid", "==", localStorage.getItem("userUid"))
  );

  const getPeople = async () => {
    const data = await getDocs(PostPeopleColl);
    console.log(`GetData From FireStore`);
    setPeopleList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    getPeople();
  }, []);

  const deletePeople = async (id) => {
    // ask before delete

    try {
      if (window.confirm("Are you sure you want to delete this person?")) {
        const postDoc = doc(db, "people", id);
        await deleteDoc(postDoc);
        getPeople();
      }
    } catch (error) {
      console.log(`error`, error.message);
    }
  };

  const EditPeople = async (id) => {
    navigate(`/EditPeople/${id}`);
  };

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <div className="p-4 box mt-3">
        <div className="d-flex justify-content-center">
          <Button
            className="btn btn-primary"
            onClick={() => navigate("/CreatePeople")}
          >
            Add New Person
          </Button>
        </div>

        <br></br>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>

              <th>Picture</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody className="post">
            {peopleList.map((post) => {
              return (
                <tr>
                  <td> {post.Name} </td>

                  <td>
                    {" "}
                    <img src={post.PicUrl} width="50px"></img>{" "}
                  </td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => {
                        EditPeople(post.id);
                      }}
                    >
                      Edit
                    </Button>{" "}
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => {
                        deletePeople(post.id);
                      }}
                    >
                      Delete
                    </Button>{" "}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

   
      <br></br>
    </>
  );
};

export default Home;
