import ReactDOM from "react-dom";
import React, { useState, useRef } from "react";

import "./SendMessage.css";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { addDoc, collection } from "firebase/firestore";
import { db, auth } from "../firebase-config";

import QRcode from "react-qr-code";

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0, .7)",
};

export default function SendMessage({ open, people, onClose }) {
  const [textmessage, settextmessage] = useState("");
  const [input, setInput] = useState("");
  const [layout, setLayout] = useState("default");
  const keyboard = useRef();

  if (!open) return null;

  if (open) console.log("open");
  if (!open) console.log("closed");

  console.log("Mail inside : " + people.Email);

  /*
  const onSend = async (toMail, subjust, bodymessage) => {
    const requestEndpoint = "https://api.merkurpos.com/api/mail/";

    const myHeaders = new Headers();

    const formdata = new FormData();
    formdata.append("ToEmail", toMail);
    formdata.append("Subject", subjust);
    formdata.append("Body", bodymessage);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const responses = await fetch(requestEndpoint + "send", requestOptions)
      .then((response) => responses.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));

      onClose();
    console.log("Send button clicked : " + toMail);
    console.log("Send button clicked : " + subjust);
    console.log("Send button clicked : " + bodymessage);
  };
*/

  const onSend = async (toMail, subjust, bodymessage) => {

    const PostMail = collection(db, "mail");
    try {
      await addDoc(PostMail, {
        to: toMail,
        message: {
          subject: subjust,
          text: bodymessage,
          html: bodymessage,
        }
      
      });

      onClose();
    } catch (error) {
      console.log("ERROR : " + error.message);
    } 

  };

  const onChange = (input) => {
    setInput(input);
    settextmessage(input);

    console.log("Input changed", input);
  };

  const onKeyPress = (button) => {
    console.log("Button pressed", button);
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const onChangeInput = (e) => {
    const input = e.target.value;
    setInput(input);
    settextmessage(input);
    keyboard.current.setInput(input);
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  console.log("Email : " + people.Email);

  if (open)
    return ReactDOM.createPortal(
      <>
        <div style={OVERLAY_STYLES}></div>
        <div class="rounded-md ModelForm">
          <div class="container">
            <img
              className="sendLogo"
              src={
                people.PicUrl !== ""
                  ? people.PicUrl
                  : "https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=2000"
              }
              alt=""
              height="200"
            ></img>

            <text className="text-1xl NameHeader">
              <h1 className="text-1xl personName">{people.Name}</h1>
              <p className="text-1xl personDescription">
                {people.AboutDescription}
              </p>
             
              
              {people.HomePageUrl ? (
              <QRcode value={people.HomePageUrl} size={50} title="www" />

              ) : (
                <div></div>
              )}

            </text>
            

            <textarea
              className="textmelding"
              name="comment"
              form="usrform"
              onChange={onChangeInput}
              value={input}
            >
              Skriv melding her...
            </textarea>

            <div className="Keyb">
              <Keyboard
                keyboardRef={(r) => (keyboard.current = r)}
                layoutName={layout}
                onChange={onChange}
                onKeyPress={onKeyPress}
                theme={"hg-theme-default myTheme1"}
              />
            </div>

            <div className="buttons">
              <button className="buttonClose" onClick={onClose}>
                {" "}
                Lukk{" "}
              </button>

              {people.Email ? (
                <button
                  className="buttonSend"
                  onClick={() =>
                    onSend(
                      people.Email,
                      "Business Jessheim Resepsjon",
                      textmessage
                    )
                  }
                >
                  {" "}
                  Send Melding{" "}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </>,
      document.getElementById("modalpop")
    );
}
