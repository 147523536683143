import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { auth } from "../firebase-config";

import { getDoc, doc, updateDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase-config";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password, Displayname) {
    // create a new user and then update the display name of the user
    console.log("displname11", Displayname);
    return createUserWithEmailAndPassword(
      auth,
      email,
      password,
      Displayname
    ).then(async (userCredential) => {
      console.log("auth", auth);
      console.log("displname", Displayname);

      console.log("update the DB", Displayname);
      const docRef = doc(db, "users", auth.currentUser.uid);
      await setDoc(docRef, {
        Name: Displayname,
        Email: email,
        PicUrl: "",
        phoneNumber: "",
        CompanyName: "",
        CompanyLogo: "",
        CompanyBackgroundColor: "",
      }).catch(function (error) {
        const message = error.message;
        console.log(message);
      });

      // also update the auth user displayname
      await updateProfile(auth.currentUser, {
        displayName: Displayname,
      });
    });

    // insert a new user in the database

    // update displayname after signup
    //return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    localStorage.removeItem("userUid");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userProviderId");
    return signOut(auth);
  }

  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      //console.log("Auth", currentuser);
      setUser(currentuser);
      localStorage.setItem("userUid", currentuser.uid);
      localStorage.setItem("userEmail", currentuser.email);
      localStorage.setItem(
        "userProviderId",
        currentuser.providerData[0].providerId
      );
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, logIn, signUp, logOut, googleSignIn }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
