import "./App.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import KioskPage from "./pages/kioskpage";
import Profile from "./pages/Profile";
import NotFound from "./pages/NotFound";
import CreatePeople from "./pages/CreatePeople";
import EditPeople from "./pages/EditPeople";
import Signup from "./pages/Signup";
import { UserAuthContextProvider, user } from "./context/UserAuthContext";
import { auth } from "./firebase-config";

import ProtectedRoute from "./ProtectedRoute";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import NavBarContents from "./pages/NavBarContents";

import { useEffect, useState } from "react";

// https://blog.openreplay.com/authentication-in-react-18-using-firebase-v9/

// https://www.youtube.com/watch?v=Ul3y1LXxzdU  --  Router DEMO youtube

function App() {
  const [userEmail, setuserEmail] = useState();

  // update navbar when user has logged in and out

  useEffect(() => {
    setuserEmail(localStorage.getItem("userEmail"));
  }, [userEmail]);

  return (
    <>
      {window.location.pathname !== "/KioskPage" && (
        <Navbar bg="primary" variant="dark" expand="lg">
          <Container>
            <Navbar.Brand href="/home">HeiApp!</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <UserAuthContextProvider>
              <NavBarContents />
            </UserAuthContextProvider>
          </Container>
        </Navbar>
      )}

      <Container fluid className="MainSizeWindow">
        <Row>
          <Col align="center">
            <UserAuthContextProvider>
              <Routes>
                <Route
                  path="/home"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/CreatePeople"
                  element={
                    <ProtectedRoute>
                      <CreatePeople />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/Profile"
                  element={
                    <ProtectedRoute>
                      <Profile />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/EditPeople/:id"
                  element={
                    <ProtectedRoute>
                      <EditPeople />
                    </ProtectedRoute>
                  }
                />

                <Route path="/" element={<Login />} />
                <Route path="/Signup" element={<Signup />} />
              </Routes>
            </UserAuthContextProvider>
          </Col>
        </Row>
      </Container>

      <UserAuthContextProvider>
        <Routes>
          <Route
            path="/KioskPage"
            element={
              <ProtectedRoute>
                <KioskPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </UserAuthContextProvider>
    </>
  );
}

export default App;
