import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import { Button } from "react-bootstrap";
import { Form, Alert } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  getDocs,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { db, auth } from "../firebase-config";
import "./CreatePeople.css";

const CreatePeople = () => {
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();

  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [PicUrl, setPicUrl] = useState("");
  const [MaxPeopleAllowed, setMaxPeopleAllowed] = useState(30);
  const [CountPeople, setCountPeople] = useState(0);
  const MaxAllowed = 30;

  // check count of people in the collection and store the count in a variable

  useEffect(() => {
    getPeopleCount();
  }, []);

  const getPeopleCount = async () => {
    const coll = collection(db, "people");
    const query_ = query(
      coll,
      where("User.userUid", "==", localStorage.getItem("userUid"))
    );
    const snapshot = await getCountFromServer(query_);
    setCountPeople(snapshot.data().count);
    setMaxPeopleAllowed(MaxAllowed - snapshot.data().count);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const PostPeopleColl = collection(db, "people");

    try {
      await addDoc(PostPeopleColl, {
        Name,
        Email,
        PicUrl,
        User: {
          usermail: auth.currentUser.email,
          userUid: auth.currentUser.uid,
        },
      });

      navigate("/home");
    } catch (error) {
      console.log("ERROR : " + error.message);
    }
  };

  return (
    <>
      <div className="CreateContainer">
        <div className="p-4 box mt-3 text-center">
          <img
            src="https://icons.veryicon.com/png/o/miscellaneous/common-icons-in-the-background-2/user-group-29.png"
            align="center"
            className="img-thumbnail shadow-4"
            width={150}
            alt="..."
          />
        </div>

        {CountPeople < MaxAllowed ? (
          <Alert variant="info">
            You can add {MaxPeopleAllowed} more people, your have now{" "}
            {CountPeople}
          </Alert>
        ) : (
          <Alert variant="danger">
            You have reeched maximum entries of {MaxAllowed}, please upgrade
            your account
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicText">
            <Form.Control
              type="text"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicText">
            <Form.Control
              type="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicText">
            <Form.Control
              type="text"
              placeholder="PicUrl"
              onChange={(e) => setPicUrl(e.target.value)}
            />
          </Form.Group>

          {CountPeople < MaxAllowed && (
            <div className="d-grid gap-2">
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          )}
        </Form>
      </div>
    </>
  );
};

export default CreatePeople;
