import React, { useState } from "react";

import "./Login.css";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import GoogleButton from "react-google-button";
import { useUserAuth } from "../context/UserAuthContext";
import { useNavigate } from "react-router-dom";

import {
    updateProfile,
    updateEmail,
    updatePassword,
    updatePhoneNumber,
  } from "firebase/auth";

const Signup = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Confirmpassword, setConfirmPassword] = useState("");


  const [Displayname, setDisplayname] = useState("");
  const [error, setError] = useState("");

    const { auth, signUp, user } = useUserAuth();
    const navigate = useNavigate();





    const handleSubmit = async (e) => {
        e.preventDefault();
      
// check if the password and confirm password are same or not
        if (password !== Confirmpassword) {
            setError("Password and Confirm Password are not same");
            return;
        }

      
        setError("");
        try {
           
           
            await signUp(email, password, Displayname);
            
                 navigate("/home");
        } catch (err) {
            setError(err.message);
        }
    };

    const handleSignUp = () => {
        navigate("/signup");
    };


  return (
    <>
      <div className="LoginContainer">
        <div className="p-4 box">
          <img
            src="https://i.pinimg.com/originals/6b/1b/22/6b1b22573f9f3d4bba11a9fa5cb45652.png"
            className="img-fluid shadow-4"
            alt="..."
          />
          <p></p>
          <h2 className="mb-3">Signup</h2>

         
            {error && <Alert variant="danger">{error}</Alert>}
          
            <Form onSubmit={handleSubmit}>


            <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control

                        type="text"
                        placeholder="Name"
                        value={Displayname}
                        onChange={(e) => setDisplayname(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control

                        type="email"
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        value={Confirmpassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </Form.Group>





                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </div>
        </div>
    </>
    );
};






export default Signup;
