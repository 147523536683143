import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import { useNavigate } from "react-router";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from "react-bootstrap/Image";

const NavBarContents = () => {
  const { user, logOut } = useUserAuth();
  const navigate = useNavigate();

  console.log(`NavBar', user`);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  if (user) {
    return (
      <>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/home">Home</Nav.Link>

            <Nav.Link href="/KioskPage">Kiosk Mode</Nav.Link>
          </Nav>

          <Nav className="me-justify-content-end">
            
            <NavDropdown title={user.displayName} id="basic-nav-dropdown">
              <NavDropdown.Item href="/Profile">Profile</NavDropdown.Item>
              <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>

         

          </Nav>
        </Navbar.Collapse>
      </>
    );
  }

  //return children;
  // console.log("Check user in Private: ", user);
};

export default NavBarContents;
