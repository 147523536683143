import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDy-m_KbatUjriRBE8jY68aY6oMidDlaHY",
  authDomain: "virtualreceptionadmin.firebaseapp.com",
  projectId: "virtualreceptionadmin",
  storageBucket: "virtualreceptionadmin.appspot.com",
  messagingSenderId: "754164173457",
  appId: "1:754164173457:web:83f3aedc2984107eb50d30",
  measurementId: "G-WDSVYH743X",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const auth = getAuth(app);

export function logIn(email, password) {
  const userAuthContext = createContext();

  return signInWithEmailAndPassword(auth, email, password);
}

export function logOut() {
  return signOut(auth);
}

export default app;
