import { auth } from "../firebase-config";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import { Button, Form, Alert } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  updateProfile,
  updateEmail,
  updatePassword,
  updatePhoneNumber,
} from "firebase/auth";
import "../App.css";

import { getDoc, doc, updateDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase-config";

const Profile = () => {
  
  const [error, setError] = useState("");
  
  const { logOut, user } = useUserAuth();

  const navigate = useNavigate();

  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [PicUrl, setPicUrl] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [password, setpassword] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyLogo, setCompanyLogo] = useState("");
  const [CompanyBackgroundColor, setCompanyBackgroundColor] = useState();

 // console.log('Color', CompanyBackgroundColor);


  const getUser = async () => {
    const docRef = doc(db, "users", auth.currentUser.uid);
    const data = await getDoc(docRef);

    console.log("Get data from DB");

    if (data.exists()) {
      setName(data.data().Name);
      setEmail(data.data().Email);
      setPicUrl(data.data().PicUrl);
      setphoneNumber(data.data().phoneNumber);
      setCompanyName(data.data().CompanyName);
      setCompanyLogo(data.data().CompanyLogo);
      setCompanyBackgroundColor(data.data().CompanyBackgroundColor);
      console.log("Found data in DB");
    }
  };

  useEffect(() => {
    if (user) {
      setName(user.displayName);
      setEmail(user.email);
      setPicUrl(user.photoURL);
      setphoneNumber(user.phoneNumber);

      getUser();
    }
  }, [user]);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateProfile(auth.currentUser, {
        displayName: Name,
        photoURL: PicUrl,
      });
      await updateEmail(auth.currentUser, Email);
      await updatePassword(auth.currentUser, password);
      await updatePhoneNumber(auth.currentUser, phoneNumber);
    } catch (error) {
      console.log(error.message);
    }

  
    // check if user is in the database and update the user or create a new user
    const docRef = doc(db, "users", auth.currentUser.uid);
    const data = await getDoc(docRef);

    if (data.exists()) {
      await updateDoc(docRef, {
        Name,
        Email,
        PicUrl,
        phoneNumber,
        CompanyName,
        CompanyLogo,
        CompanyBackgroundColor,
      });
    } else {
      await setDoc(docRef, {
        Name,
        Email,
        PicUrl,
        phoneNumber,
        CompanyName,
        CompanyLogo,
        CompanyBackgroundColor,
      });
    }
  };

  return (
    <>
      <div className="AppContainer">
        <div className="p-4 box mt-3 text-center">
          <br />

          <img
            src={
              PicUrl !== ""
                ? PicUrl
                : "https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=2000"
            }
            alt=""
            height="150"
          ></img>
          <br />

          <br />

          {user.phoneNumber}

          <br />

          <Form onSubmit={handleSubmit}>
            {error && <Alert variant="danger">{error}</Alert>}

            <h4>User</h4>
            <hr></hr>

            <Form.Group className="mb-3" controlId="formBasicText">
              <Form.Control
                type="text"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
                value={Name}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicText">
              <Form.Control
                // readOnly
                disabled
                type="text"
                placeholder="Name"
                onChange={(e) => setEmail(e.target.value)}
                value={Email}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicText">
              <Form.Control
                type="text"
                placeholder="PicUrl"
                onChange={(e) => setPicUrl(e.target.value)}
                value={PicUrl}
              />
            </Form.Group>

            <br />

            <div
              style={{
                backgroundColor: CompanyBackgroundColor,
              }}
            >
              <img
                src={
                  CompanyLogo !== ""
                    ? CompanyLogo
                    : "https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=2000"
                }
                alt=""
                height="150"
              ></img>
            </div>
            <br />
            <br></br>
            <h4>Company</h4>
            <hr></hr>

            <Form.Group className="mb-3" controlId="formBasicText">
              <Form.Control
                type="text"
                placeholder="CompanyName"
                onChange={(e) => setCompanyName(e.target.value)}
                value={CompanyName}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicText">
              <Form.Control
                type="text"
                placeholder="CompanyLogo"
                onChange={(e) => setCompanyLogo(e.target.value)}
                value={CompanyLogo}
              />
            </Form.Group>

            <Form.Label style={{
              
              display: "flex",
              justifyContent: "left",
              fontSize: "15px",
              // fontWeight: "bold",
              // color: "black",
            
                color: "#282c34",
              }} htmlFor="exampleColorInput">Background Color</Form.Label>
            <Form.Control
              type="color"
              id="exampleColorInput"
              defaultValue={CompanyBackgroundColor} 
              value={CompanyBackgroundColor}
              title="Background Color"
              onChange={(e) => setCompanyBackgroundColor(e.target.value)}
              
            />
<br></br>
            {localStorage.getItem("userProviderId") !== "google.com" && (
              <>
                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={user.email}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Control
                    type="text"
                    placeholder="PicUrl"
                    onChange={(e) => setPicUrl(e.target.value)}
                    value={user.photoURL}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Control
                    type="text"
                    placeholder="phoneNumber"
                    onChange={(e) => setphoneNumber(e.target.value)}
                    value={user.phoneNumber}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Control
                    type="text"
                    placeholder="password"
                    onChange={(e) => setpassword(e.target.value)}
                    value={user.password}
                  />
                </Form.Group>
              </>
            )}

            <div className="d-grid gap-2">
              <Button variant="primary" type="submit">
                Update
              </Button>
            </div>
          </Form>
        </div>

     
      </div>
    </>
  );
};

export default Profile;
