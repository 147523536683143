import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import { Button } from "react-bootstrap";
import { Form, Alert, CardGroup, Card } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { db, auth, user } from "../firebase-config";
import "./kioskpage.css";
import SendMessage from "./SendMessage";
import ReactDOM from "react-dom";
import { render } from "@testing-library/react";

const KioskPage = () => {
  const { user } = useUserAuth();

  const [CompanyName, setCompanyName] = useState("");
  const [CompanyLogo, setCompanyLogo] = useState("");
  const [CompanyBackgroundColor, setCompanyBackgroundColor] =
    useState("#282c34");

  const [input, setInput] = useState("");
  const [peopleList, setPeopleList] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const imageClick = (isOpen, people) => {
    const modalpop = ReactDOM.createRoot(document.getElementById("modalpop"));
    modalpop.render(
      <SendMessage
        open={isOpen}
        people={people}
        onClose={() => imageClick(false)}
      ></SendMessage>
    );

    console.log(isOpen);
  };

  const newFunction = (peopleList, input) => {
    return (
      <div className="kiosk__container">
        {peopleList
          .filter((people) => people.Name.toLowerCase().includes(input))
          .map((people) => {
            return (
              <>
                <div className="persons">
                  <img
                    className="personPic"
                    src={
                      people.PicUrl !== ""
                        ? people.PicUrl
                        : "https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=2000"
                    }
                    alt=""
                    height="100"
                    onClick={() => imageClick(true, people)}
                  ></img>
                  <p className="peoplename"> {people.Name} </p>
                </div>
              </>
            );
          })}
      </div>
    );
  };

  const getUser = async () => {
    const docRef = doc(db, "users", localStorage.getItem("userUid"));
    const data = await getDoc(docRef);

    console.log("Get data from DB");

    if (data.exists()) {
      setCompanyName(data.data().CompanyName);
      setCompanyLogo(data.data().CompanyLogo);
      setCompanyBackgroundColor(data.data().CompanyBackgroundColor);
      console.log("Found data in DB");
    }
  };

  const getPeople = async () => {
    const peoplePeopleColl = query(
      collection(db, "people"),
      where("User.userUid", "==", localStorage.getItem("userUid"))
    );

    const data = await getDocs(peoplePeopleColl);
    console.log(`GetData From FireStore`);
    setPeopleList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    getPeople();
    getUser();
  }, []);

  return (
    <>
      <div
        className="kiosk"
        style={{
          backgroundColor: CompanyBackgroundColor,
        }}
      >
       
       <div className="centerBusinessLogo">
        {CompanyLogo !== "" ? ( 
        <img src={CompanyLogo} alt="Logo" class="centerBusinessLogo" />
        ) : null}
</div>
        <h1></h1>

        <div className="kioskSearch">
          <input
            type="text"
            name="name"
            value={input}
            onChange={(e) => setInput(e.target.value.toLowerCase())}
            placeholder="Search"
            style={{
              backgroundColor: CompanyBackgroundColor,
            }}
          />
        </div>

        {newFunction(peopleList, input)}
      </div>
    </>
  );
};

export default KioskPage;
