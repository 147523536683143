import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "./context/UserAuthContext";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

const ProtectedRoute = ({ children }) => {
  const { user } = useUserAuth();
  
  console.log(`user', user`);

  if (!user) {
    return <Navigate to="/" />;
  }

 
  return children;
  // console.log("Check user in Private: ", user);
};

export default ProtectedRoute;
